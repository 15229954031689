<template>
  <div class="wrapper">
      <!-- <div>
          <img src="@/assets/images/nosotros/background_illustration_NOSOTROS.svg" alt="">
      </div> -->
      <div class="tips-top">
        <img class="tips-cover" src="../assets/images/Asset_2.svg" alt="">
        <h1 style="text-transform:uppercase;">Tips para emisiones</h1>
      </div>
      <div class="content">
          
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num1.svg" alt="">
                  <div>Tip VVMóvil 1: <strong>CO,CO2 fuera de rango</strong></div>
              </div>
              <div class="box">
                  <p>Cuando los valores de CO y CO2 se encuentran fuera de rango esto suele indicar que existe una combustión deficiente.</p>
              </div>
              <div class="box">
                  <p>
                      Recomendamos revisar:
                    <ul>
                        <li>
                            Sensores de Oxígeno
                        </li>
                        <li>Filtro de aire</li>
                        <li>Limpieza de Inyectores</li>
                        <li>Bomba de combustible</li>
                    </ul>
                    Si el vehículo obtuvo emisiones bajas de Bióxido de Carbono (CO2) recomendamos:
                    <ul>
                        <li>Revisar todo lo anterior</li>
                        <li>Y el convertidor catalítico</li>
                    </ul>
                  </p>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num2.svg" alt="">
                  <div>Tip VVMóvil 2: <strong>HC Alto</strong></div>
              </div>
              <div class="box">
                  <p>
                    Típicamente un valor alto de Hidrocarburos (HC) indica también un valor alto de Mónóxido de Carbono(CO) todo esto debido a una mezcla rica (exceso de combustible) en el sistema de inyección del vehículo.
                  </p>
              </div>
              <div class="box">
                  <p>
                      Recomendamos: 
                      <ul>
                          <li>Realizar una Afinación</li>
                          <li>Limpiar los Inyectores</li>
                          <li>Cambiar las Bujías</li>
                      </ul>
                  </p>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num3.svg" alt="">
                  <div>Tip VVMóvil 3: <strong>Lambda y Oxígeno fuera de rango</strong></div>
              </div>
              <div class="box">
                  <p>
                   Lambda y Oxígeno fuera de rango son el indicio de posibles fugas en el sistema de escape (tubo o silenciador roto) y/o problemas con los sensores de oxígeno que pueden requerir reemplazo.
                  </p>
              </div>
              <div class="box">
                  <p>
                      Recomendamos: 
                      <ul>
                          <li>Reparar o reemplazar el silenciador y/o tubo de escape</li>
                          <li>Revisar, y en su caso, reemplazar los sensores de oxígeno</li>
                      </ul>
                  </p>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num4.svg" alt="">
                  <div>Tip VVMóvil 4: <strong>NOx (NO + NO2) fuera de rango</strong></div>
              </div>
              <div class="box">
                  <p>
                    Cuando los NOxes (óxido de nitrógeno y bióxido de nitrógeno) se encuentran fuera de rango esto puede indicar cualquiera de las siguientes dos situaciones: (i) la válvula EGR tiene mal funcionamiento o (ii) el convertidor catalítico no está funcionando correctamente.
                  </p>
              </div>
              <div class="box">
                  <p>
                      Recomendamos: 
                      <ul>
                          <li>En el primer caso, revisar la válvula EGR</li>
                          <li>Y en el segundo caso, revisar el sistema de enfriamiento</li>
                      </ul>
                  </p>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num5.svg" alt="">
                 <div> Tip VVMóvil: 
                  <strong>Mantenimiento</strong></div>
              </div>
              <div class="box">
                  <p>
                      Para mantener un vehículo en condiciones óptimas recomendamos realizar mantenimientos preventivos de manera recurrente. 
                  </p>
              </div>
              <div style="text-align:center; ">
                <strong> Un vehículo en buenas condiciones contribuye a reducir <br>
las emisiones contaminantes vehiculares.</strong>

              </div>
              <div>
                  <p>
                  La próxima vez que programes una revisión te recomendamos realizar el mantenimiento de 4 a 7 días previos a tu cita.  El realizar una revisión 1 o 2 días antes de la verificación compromete los resultados de la prueba ya que, al hacer el mantenimiento, pueden quedar residuos de aceite o de los limpiadores en el motor del vehículo.
                  </p>
              </div>
              <div>
                  <table>
                      <tr>
                          <td>
                              •	Aceite/Filtro de aceite-aire:
                          </td>
                          <td>7,500 kms</td>
                      </tr>
                      <tr>
                          <td>
                              •	Bujias y cables:
                          </td>
                          <td>30,000 - 40,000 kms</td>
                      </tr>
                      <tr>
                          <td>
                              •	Convertidor Catalítico:
                          </td>
                          <td>120,000 kms</td>
                      </tr>
                      <tr>
                          <td>
                              •	Válvula EGR (si aplica):
                          </td>
                          <td>40,000 kms</td>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="legal">
             <strong> AVISO DE NO RESPONSABILIDAD</strong>
              <p>
                  Las recomendaciones hechas por VVMOVIL en ningún momento se considerará que garantizan que la segunda o ulteriores pruebas que se realicen a dicha Unidad resultará positiva. Por lo que VVMOVIL no tendrá responsabilidad alguna de los costos, gastos, daños o perjuicios que puedan resultar de las acciones del propietario de la Unidad, el Usuario o el Cliente en atención a las recomendaciones realizadas por VVMOVIL respecto a una Unidad.
              </p>
          </div>
    
          <!-- <p>
            VVMóvil, somos una empresa comprometida con el medio ambiente, unimos nuestros conocimientos y esfuerzos para promover activamente entre la población una cultura ambiental reflexiva, que impulse acciones enfocadas a la protección del medio ambiente, el control y prevención de la contaminación del aire. 
          </p>
          <p>
           VVMóvil no persigue excluir o sustituir los centros de verificación fijos, nuestro propósito es concientizar a la población de la importancia de que su vehículo cumpla con los estándares establecidos y para ello nosotros brindamos una opción práctica, flexible y segura, que ayudará a cada habitante y Gobierno de nuestro país a preservar lo más importante, nuestra calidad de vida, salud y bienestar común. 
          </p>

          <router-link to="/contacto" ><button class="router-btn">Contacto</button></router-link> -->
      </div>
      <div class="btm-skyline">
          <img src="../assets/images/skyline-bottom.svg" alt="vvmovil-tips">
      </div>
  </div>
</template>

<script>
export default {
  name: 'Tips',
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
    background-color: #E6EEEE;
}
.tips-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 950px)  {
                align-items: center;
    }
    .tips-cover {
        margin-top: -5%;
        width: 100%;
        z-index: 0;
            @media screen and (max-width: 950px)  {
                width: 100%;
                margin-top: -10%;
            }

    }
    h1 {
        z-index: 2;
        margin-left: 15%;
        margin-top: -15%;
        padding-bottom: 130px;
        @media screen and (max-width: 950px)  {
                margin-top: unset;
                padding-bottom: unset;
                margin-left: unset;

            }
    }
}
.content{
    text-align: left;
    margin-top: 6%;
    
    .tip{
        margin-bottom:60px;
        .header{
            background-color: #0688aa;
            img{
                height: 80px;
                position: relative;
                left: -30px;
            }
            height: 50px;
            display: flex;
            align-items: center;
            color: white;
            font-size: 1.2rem;
            border-radius: 10px;
            @media screen and (max-width: 440px)  {
                height: auto;
                img{
                    left: 0;
                }
            }
        }
        .box{
            // border: 1px solid;
        }
    }
    .legal{
        font-size: 0.7rem;
        margin-top: 70px;
        p{
            margin-top: 2px;
        }
    }
}
.btm-skyline {
    z-index: 0;
    overflow:hidden;

    img {
        margin-left: -15px;
        margin-bottom: -25.5%;
    }
}
</style>
